import React from 'react';
import Footer from './Footer';
// import Homehero from './subpages/Homehero';
// import Banner from './subpages/Banner';
import CurrentNews from './subpages/CurrentNews';
// import Currentbook from './subpages/Currentbook';
import AbouttheAuthor from './subpages/AbouttheAuthor';
// import Domestika from './subpages/Domestika';
import NextBook from './subpages/NextBook';
import Canadabanner from './subpages/Canadabanner';
import TsirsRelease from './subpages/TsirsRelease';
import Newssection from './subpages/Newssection';
import Slideshow from './subpages/Slideshow';
import Reviews from './subpages/Reviews';
import './Home.css';

function Home() {
  return (
    <div className='home'>
      <div className='homepage'>
        {/* <Homehero /> */}
        <main>
          {/* <Banner /> */}
          <TsirsRelease />
          <NextBook />
          <Canadabanner />
          <AbouttheAuthor />
          {/* <Domestika /> */}
          {/*  */}
          {/* <Currentbook /> */}
          <CurrentNews />
          <Newssection />
          <Slideshow />
          <Reviews />
          {/*  */}
        </main>
      </div>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default Home;
