import React from 'react';
import './Canadabanner.css';

function Canadabanner() {
  return (
    <div className='canadabanner py-4 py-md-5 px-3'>
      <h3 className='mb-4'>MORE BREAKING NEWS</h3>
      <p>
        <i>I DIED ON A TUESDAY</i> – NOW A NATIONAL BEST-SELLER IN CANADA
      </p>

      <p className='d-block my-0'>
        <a
          href='https://www.penguinrandomhouse.ca/books/735470/i-died-on-a-tuesday-by-jane-corry/9780385697934'
          alt='link to get I died on a Tuesday in canada'
          target='_blank'
          rel='noreferrer'
          className='linkLightBase px-1'>
          Click <u>here</u> to order in Canada
        </a>
      </p>
      <p className='d-block my-0'>
        <a
          href='https://www.penguinrandomhouse.com/books/735470/i-died-on-a-tuesday-by-jane-corry/'
          alt='link to get I Died On a Tuesday in usa'
          target='_blank'
          rel='noreferrer'
          className='linkLightBase px-1'>
          Click <u>here</u> to order in the US
        </a>
      </p>
    </div>
  );
}

export default Canadabanner;
