import React from 'react';
import {useNavigate} from 'react-router-dom';
// import podcastImage from '../../media/charlielovett.jpg';
// import ctfycanada from '../../media/book8canada.jpg';
import domestica from '../../media/domestica.png';
import idoatcanada from '../../media/book9canada.jpg';
import canadalist from '../../media/canadabestseller.png';

import './Newssection.css';

function Newssection() {
  let navigate = useNavigate();
  return (
    // Template:
    // ff
    //  <div className='col-12 col-lg-4 order-1 pt-3 pt-lg-0 '>
    //         <div className='cards p-2'>
    //           <p className='pt-2 cardtext'>
    //             <img
    //               src={   }
    //               alt='   '
    //               className=' float-start img-fluid d-inline px-2 cardImage mb-3'
    //             />
    //           TEXT TEXT
    //           </p>
    //           <div className='mt-4'>
    //             <a
    //               href='  '
    //               alt='  '
    //               target='_blank'
    //               rel='noreferrer'
    //               className='linkLightBase'>
    //               TEXT TEXT
    //             </a>
    //           </div>
    //         </div>
    //       </div>
    <div className='newssection'>
      <div>
        <h2>News</h2>

        <div className='row m-auto newsCardContainer  justify-content-md-center'>
          {/* Podcast */}
          {/* <div className='col-12 col-lg-4 order-2 pt-3 pt-lg-0 '>
            <div className='cards p-2'>
              <p className='pt-2 cardtext'>
                <img
                  src={podcastImage}
                  alt='cover of Charlie Lovetts Podcast about books and authors'
                  className=' float-start img-fluid d-inline px-2 cardImage mb-3'
                />
                Recently I was lucky enough to be interviewed by top-selling US
                author <i>Charlie Lovett </i>on his podcast
              </p>
              <div className='mt-4'>
                <a
                  href='https://charlielovett42.podbean.com/e/jane-corry-1152024-inside-the-writers-studio-episode-127/'
                  alt='Charlie Lovetts Pdcast, Episode about Jane Corry'
                  target='_blank'
                  rel='noreferrer'
                  className='linkLightBase'>
                  Listen to the episode here
                </a>
              </div>
            </div>
          </div> */}
          {/* Canada bestseller list */}
          <div className='col-12 col-lg-4 order-1 pt-3 pt-lg-0 '>
            <div className='cards p-2'>
              <p className='pt-2 canadalist'>
                <div className='canadaimagecontainer'>
                  <a
                    href={canadalist}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='linkLightBase'> <img
                    src={canadalist}
                    alt='list of Canada bestseller in January'
                    className='img-fluid d-block mx-auto px-2 canadaImage'
                  />
                  <p className='mb-3 canadaenlarge'>click to enlarge</p></a>
                 
                </div>
                I’m very excited that <br />
                <i>I Died On a Tuesday </i>
                <br />
                was number 2 in the best-selling fiction paperbacks in Canada
                shortly after publication in January.
              </p>
            </div>
          </div>
          {/* IDOAT */}

          <div className='col-12 col-lg-4 order-2  pt-3 pt-lg-0'>
            <div className='cards p-2'>
              <p className='pt-2 cardtext d-inline-block h-100'>
                <img
                  src={idoatcanada}
                  alt='Canadian cover of  I Died On a Tuesday'
                  className=' float-start img-fluid d-inline px-2 cardImage mb-3'
                />
                <p className='mt-3 mb-0 '>
                  <i> I Died On a Tuesday</i> is now available in Canada and the
                  US.
                </p>
              </p>
              <div className='mt-2'>
                <a
                  href='https://www.penguinrandomhouse.com/books/735469/coming-to-find-you-by-jane-corry/'
                  alt='link to buy  I Died On a Tuesday in canada'
                  target='_blank'
                  rel='noreferrer'
                  className='linkLightBase'>
                  Click <u>here</u> to order in Canada
                </a>
              </div>
              <div>
                {' '}
                <a
                  href='https://www.amazon.com/Coming-Find-You-Jane-Corry-ebook/dp/B0BYYNF1YX/ref=sr_1_3?keywords=jane+corry&qid=1699120785&sr=8-3'
                  alt='amazon link to  I Died On a Tuesday in usa'
                  target='_blank'
                  rel='noreferrer'
                  className='linkLightBase'>
                  Click <u>here</u> to order in the US
                </a>
              </div>
            </div>
          </div>
          {/* ctfy canada*/}

          {/* <div className='col-12 col-lg-4 order-3  pt-3 pt-lg-0'>
            <div className='cards p-2'>
              <p className='pt-2 cardtext'>
                <img
                  src={ctfycanada}
                  alt='Canadian cover of Coming to find you'
                  className=' float-start img-fluid d-inline px-2 cardImage mb-3'
                />
                <p className='mt-3 mb-0 '>
                  <i>Coming To Find You</i> is now available in Canada and the
                  US.
                  <br />
                  published by
                  <a
                    href='https://www.penguinrandomhouse.ca/imprints/4H/doubleday-canada'
                    alt='Doubleday publishing'
                    target='_blank'
                    rel='noreferrer'
                    className='linkLightBase'>
                    {'  '}Doubleday {'  '}
                  </a>
                </p>
              </p>{' '}
              <div className='mt-3'>
                <a
                  href='https://www.penguinrandomhouse.com/books/735469/coming-to-find-you-by-jane-corry/'
                  alt='link to buy Coming to find you in canada'
                  target='_blank'
                  rel='noreferrer'
                  className='linkLightBase'>
                  Click here to order in Canada
                </a>
              </div>
              <div>
                {' '}
                <a
                  href='https://www.amazon.com/Coming-Find-You-Jane-Corry-ebook/dp/B0BYYNF1YX/ref=sr_1_3?keywords=jane+corry&qid=1699120785&sr=8-3'
                  alt='amazon link to Coming to find you in usa'
                  target='_blank'
                  rel='noreferrer'
                  className='linkLightBase'>
                  Click here to order in the US
                </a>
              </div>
            </div>
          </div> */}
          {/* Domestika */}
          <div className='col-12 col-lg-4 order-3 pt-3 pt-lg-0 '>
            <div className='cards p-2'>
              <p className='pt-2 domesticatext'>
                <img
                  src={domestica}
                  alt='cover of Janes domestica course'
                  className='img-fluid d-block mx-auto px-2 domesticaImage mb-3'
                />
                I was thrilled when Domestika asked me to write a short story
                course. To celebrate the launch, it’s being offered at a
                promotional price for a limited period.
              </p>
              <div className='mt-4'>
                <a
                  href='https://www.domestika.org/en/courses/5207-creative-writing-techniques-for-short-stories/janecorryauthor'
                  alt='Jane Corrys writing course on domestika'
                  target='_blank'
                  rel='noreferrer'
                  className='linkLightBase'>
                  Find the course <u>here</u>
                </a>
              </div>
            </div>
          </div>
          {/* dartmouth 
          <div className='col-12 col-lg-4 order-1  pt-3 pt-lg-0 '>
            <div className='cards p-2'>
              <p className='pt-2 cardtext'>
                <img
                  src={dartmouth}
                  alt='flyer for dartmouth literary festival'
                  className=' img-fluid d-block mx-auto px-2 domesticaImage mb-3'
                />
                I will be speaking at the Dartmouth literary festival in
                September.
              </p>
              <div className='mt-4'>
                <a
                  href='https://theflavel.org.uk/TheFlavel.dll/WhatsOn?f=8944980'
                  alt='Jane Corry at the Dartford Literary'
                  target='_blank'
                  rel='noreferrer'
                  className='linkLightBase'>
                  Find more details here
                </a>
              </div>
            </div>
          </div> */}
          {/*  */}
        </div>
      </div>
      <button
        onClick={() => {
          navigate('../../News');
        }}
        className='btnLightDark px-3   py-1 reviewLink'>
        more news
      </button>
    </div>
  );
}

export default Newssection;
