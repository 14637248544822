import React from 'react';
import book from '../../media/mumslife.png';
import './CurrentNews.css';

function CurrentNews() {
  return (
    <div className='currentNews p-3 pe-5'>
      <div className='republish'>
        <div className='col-5 bookImages'>
          <img
            src={book}
            alt='Jane Corrys republished books Its a mums life and happy families'
            className='img-fluid pt-4 book1'
          />
        </div>
        <div className='col-7 newText'>
          Before my Jane Corry books, I wrote romance as Sophie King and Janey
          Fraser. Now they're being re-published in digital and audio format by
          <a
            href='https://www.instagram.com/emblabooks/'
            alt='instagram of embla publishers'
            target='_blank'
            rel='noreferrer'
            className=' py-1 px-2 linkLightBase'>
            Embla Books.
          </a>
          So if you fancy some warm-hearted romance about family life, click
          here to find
          <p>
            <a
              href='https://geni.us/ItsaMumsLife'
              alt='Find Its a Mums Life Book'
              target='_blank'
              rel='noreferrer'
              className=' py-1 px-2 linkLightBase introLink'>
              <u>It's a Mums Life</u>
            </a>
            and
            <a
              href='https://geni.us/HappyFamilies'
              alt='Happy Families Book'
              target='_blank'
              rel='noreferrer'
              className=' py-1 px-2 linkLightBase introLink'>
              <u>Happy Families</u>.
            </a>
          </p>
          <p className='kindle p-3'>
            Look out for a daily Kindle Deal on <br />
            <i>HAPPY FAMILIES</i>
            <br />
            on Sunday January 26!
          </p>
        </div>
      </div>
    </div>
  );
}

export default CurrentNews;
