import React from 'react';
import './TsirsRelease.css';
import tsirs from '../../media/book10.jpg';

function TsirsRelease() {
  return (
    <div className='tsirsrelease py-4 py-md-5 px-3'>
      <div className='tsirsrow'>
        <h3 className='mb-4'> BREAKING NEWS</h3>
        <div className='row'>
          <div className='col-12 col-md-6 order-2 order-md-1 p-md-0'>
            <img
              src={tsirs}
              alt='Upcoming Jane Corry Book The stranger in room 6 Cover'
              className='img-fluid pt-4'
            />
          </div>
          <div className='col-12  col-md-6 order-1 order-md-2'>
            <h2>NEW JANE CORRY NOVEL. </h2><p> PUBLISHED BY PENGUIN VIKING.</p>
            <p className='tsirshighlight'>TO BE RELEASED IN JUNE 2025</p>
            <p className='pt-3'>
              Mabel was evacuated to Devon during WW2 and later in life, turned
              it into a nursing home where she is one of the residents. One of
              her carers is Belinda a mother of two, who, unknown to everyone
              else, has just been released from prison for murdering her
              husband. Mabel and Belinda share their stories and secrets. But in
              doing so, they each risk the other’s safety.
            </p>
            <p className='tsirshighlight'>THE STRANGER IN ROOM SIX</p>
            <p> Now available to pre-order.</p>
            <div className='mb-4'>
              <a
                href=' https://www.penguin.co.uk/books/462581/the-stranger-in-
                room-six-by-corry-jane/9781405975292'
                alt='get I Died On a Tuesday ebook on amazon'
                target='_blank'
                rel='noreferrer'
                className=' py-1 px-2'>
                Click here to pre-order now
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TsirsRelease;
